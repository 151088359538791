import "./css/main.css";

import React from "react";
import { createRoot } from "react-dom/client";
import logoNoText from "./img/stepwars-notext.png";
import Card from "./components/card";

const HelloWorld = () => {
  return (
    <>
      <header className="blog-header">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <a href="#" className="blog-header-logo text-dark logolink">
                <img src={logoNoText} className="my-4 mx-2"/>
                StepWars
              </a>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row mb-2">
            <Card>
                Prepare to enter step challenges with your friends!
            </Card>
            <Card>
                Here you can compete and chat with your friends to reach the highest step count!
            </Card>
        </div>
        <div className="row mb-2">
            <div className="col-12 mx-2">
                Copyright {new Date().getFullYear()} George Snyder
            </div>
        </div>
      </main>
    </>
  );
};

window.addEventListener("load", function () {
  const root = createRoot(document.getElementById("root"));
  root.render(<HelloWorld />);
});
