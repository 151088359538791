import React from "react";

const Card = ({children}) => {
  return (
    <div className="col-md-6">
      <div className="card g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div className="card-body d-flex flex-column align-item-start">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
